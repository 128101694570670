const newsTab = (() => {
  const bodyNews = (document.getElementById("bodyNews") != null);
  let newsTab = document.getElementsByClassName("news_tab");
  let newsTabCntnt = document.getElementsByClassName("news_tab_cntnt");

  if (bodyNews) {
    // console.log("bodyNews");
    for (let i = 0; i < newsTab.length; i++) {

      newsTab[i].addEventListener('click', event => {
        // let target = event.currentTarget;
        for (let j = 0; j < newsTab.length; j++) {
          newsTab[j].classList.remove('active');
          newsTabCntnt[j].classList.remove('active');
        }
        newsTab[i].classList.add('active');
        newsTabCntnt[i].classList.add('active');
      });
    }

  }
})();

export default newsTab;