const bodyTop = (document.getElementById("bodyTop") != null);
const bodyLaboratory = (document.getElementById("bodyLaboratory") != null);

export { default as userAgent } from './userAgent';
export { default as scrollControlFunc } from './scrollControlFunc';
export { default as windowResize } from './windowResize';
export { default as scrollFunc } from './scrollFunc';
export { default as navFunc } from './navFunc';
export { default as loadFunc } from './loadFunc';
export { default as loadImg } from './loadImg';
// export { default as loadImgEnd } from './loadImgEnd';

export { default as activePoint } from './activePoint';

export { default as topMain } from './topMain';
export { default as newsTab } from './newsTab';
export { default as studentsTab } from './studentsTab';



// __________________________________
// import { importName } from './index';

// const name = async () => {
//   if (bodyTop) {
//     console.log("top");
//   }
// }

// export default name;