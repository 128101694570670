import { loadImg } from './index';

const topMain = async() => {
  const bodyTop = (document.getElementById("bodyTop") != null);
  
  if (bodyTop) {
    let sW = document.documentElement.clientWidth;
    let sH = document.documentElement.clientHeight;
    let topMainWrap = document.getElementById('topMainWrap');
    let topMainImg = document.getElementById('topMainImg');
    let main_base = document.getElementById('main_base');
    let topMain1 = document.getElementById('topMain1');
    let topMain2 = document.getElementById('topMain2');
    // let topMain3 = document.getElementById('topMain3');
    // let topMain4 = document.getElementById('topMain4');
    // let topMain5 = document.getElementById('topMain5');
    // let topMain5 = document.getElementById('topMain6');
    let topCover1 = document.getElementById('topCover1');
    let topCover2 = document.getElementById('topCover2');
    let topCover3 = document.getElementById('topCover3');

    if(sW <= 768){
      topMainWrap.style.height = sH - 50 +"px";
    }
    
    const addClass = (id) => {
      id.classList.add('active');
    }
    
    await loadImg();

    setTimeout(() => addClass(topMain1), 1000);
    setTimeout(() => addClass(topMain2), 2000);
    setTimeout(() => addClass(topMain3), 2500);
    setTimeout(() => addClass(topMain4), 3000);
    setTimeout(() => addClass(topMain5), 3500);
    setTimeout(() => addClass(topMain6), 4000);
    setTimeout(() => addClass(topCover1), 3000);
    setTimeout(() => addClass(topCover2), 3500);
    setTimeout(() => addClass(topCover3), 4000);
  }
}

export default topMain;