const activePoint = (() => {
  let point = document.getElementsByClassName("comActP");
  let pointLength = point.length;
  let sH = document.documentElement.clientHeight;
  let sW = document.documentElement.clientWidth;
  let volume = 0;

  if(sW > 768){
    volume = 200;
  }else{
    volume = 100;
  }

  window.addEventListener('scroll', function () {
    for (let i = 0; i < pointLength; i++) {
      if (!point[i].classList.contains('active')) {
        let pointRect = point[i].getBoundingClientRect();
        let changePoint = window.pageYOffset + pointRect.top;
        let scroll = window.pageYOffset + sH - volume;
        // console.log(scroll);
        // console.log(changePoint);
        if (changePoint < scroll) {
          point[i].classList.add('active');
        }
      }
    }
  });

})();

export default activePoint;