import {scrollControlFunc} from './index';

const navFunc = (() => {
  let nav = document.getElementById('nav');
  let navTrigger = document.getElementById('navTrigger');
  navTrigger.addEventListener('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    if( navTrigger.classList.contains('active') == true ){
      scrollControlFunc.returnScroll();
      nav.classList.remove('active');
      navTrigger.classList.remove('active');
    }else{
      scrollControlFunc.noScroll();
      nav.classList.add('active');
      navTrigger.classList.add('active');
    }
  });
})();

export default navFunc;