const studentsTab = (() => {
  const bodyStudents = (document.getElementById("bodyStudents") != null);
  let studentsTab = document.getElementsByClassName("students_tab");
  let studentsTabCntnt = document.getElementsByClassName("students_tab_cntnt");

  if (bodyStudents) {
    for (let i = 0; i < studentsTab.length; i++) {

      studentsTab[i].addEventListener('click', event => {
        // let target = event.currentTarget;
        for (let j = 0; j < studentsTab.length; j++) {
          studentsTab[j].classList.remove('active');
          studentsTabCntnt[j].classList.remove('active');
        }
        studentsTab[i].classList.add('active');
        studentsTabCntnt[i].classList.add('active');
      });
    }

  }
})();

export default studentsTab;