import {scrollControlFunc, windowResize, topMain} from './index';

const loadFunc = (() => {
  const bodyTop = (document.getElementById("bodyTop") != null);
  let loadWrap = document.getElementById('loadWrap');
  scrollControlFunc.noScroll();
  windowResize();

  const startFunc = () => {
    loadWrap.classList.add('no_active');
    scrollControlFunc.returnScroll();

    if( bodyTop ){
      topMain();
    }else{
      // console.log("no_index");
    }
  }

  setTimeout(startFunc, 600)

})();

export default loadFunc;